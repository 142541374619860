<style scoped>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: A4 landscape;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }

        @page :footer {
            display: none;
        }
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    #title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th {
        border: 2px solid #000000;
        text-align: left;
        padding: 8px;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    #info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }
</style>

<template>
    <div>
        <!-- <img :src="logoSDN" id="logo" /> -->
        <br>
        <p id="title">Pickup</p>
        <div class="space-for-logo"></div>
        <div id="info">
            <table class="remove_border">
                <tr>
                    <th>Customer Name</th>
                    <th>:</th>
                    <th>{{ data.SalesOrder.CustomerName }}</th>
                </tr>
                <tr>
                    <th>SO Code</th>
                    <th>:</th>
                    <th>{{ data.SalesOrder.Code }}</th>
                </tr>
                <tr>
                    <th>DO Code</th>
                    <th>:</th>
                    <th>{{ data.Code }}</th>
                </tr>
            </table>
        </div>
        <table class="table table-bordered" style="width:100%">
            <thead class="vs-table--thead">
                <tr>
                    <th rowspan="1" style="vertical-align: middle; text-align:center;">SKU Name</th>
                    <th rowspan="1" style="vertical-align: middle; text-align:center;">HU</th>
                    <th rowspan="1" style="vertical-align: middle; text-align:center;">Qty</th>
                    <th rowspan="1" style="vertical-align: middle; text-align:center;">Batch Number</th>
                    <th rowspan="1" style="vertical-align: middle; text-align:center;">Expired Date</th>
                </tr>
            </thead>
            <tbody>
                <tr :key="indext" v-for="(d, indext) in data.DeliveryOrderLine">
                    <td>{{ d.PackingLine.ItemName }}</td>
                    <td>{{ d.PackingLine.ItemUnit }}</td>
                    <td>{{ d.PackingLine.Amount }}</td>
                    <td>{{ d.PackingLine.Batch }}</td>
                    <td>{{ formatDate(d.PackingLine.ExpiredDate) }}</td>
                </tr>
            </tbody>
        </table>
        <div class="">Cetakan ke {{ data.PrintCounter }}</div>
    </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
    name: "PrintPdf",
    data() {
        return {
            id: null,
            table: this.tableDefaultState(),
            sto_plan_code: null,
            picking_code: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            // logoSDN: logoSDN,
            showInfo: false,
            data: {},
        }
    },
    mounted() {
        var now = moment()
        now.add(1, 'days')

        this.reqDeliveryDate = now.toDate()
        this.eta = now.toDate()

        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            // this.getData()
            this.print();
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [
                    {
                        
                    }
                ],
            };
        },
        async print() {
            const tempTitle = document.title
            await this.getData()
            await window.print()
            document.title = tempTitle
        },
        async getData() {
            // this.$vs.loading();
            // let resp = await this.$http.get(`/api/wms/v1/simple-outbound-planner/handover-data/${this.handover_id}`);
            let resp = await this.$http.get(`/api/wms/v1/simple-outbound-planner/do/${this.$route.params.id}`, {
                params: {
                    is_print: 1
                }
            });
            console.log(resp)
            if (resp.code == 200) {
                this.data = resp.data
                document.title = `Pickup-${resp.data.ProofOfDelivery.Code}`
                // this.table = resp.data.HandoverLine;
                // this.sto_plan_code = resp.data.headers.sto_plan_code;
                // this.picking_code = resp.data.headers.picking_code;
                // this.selectedDestWarehouse = resp.data.headers.dest_warehouse_code + " - " + resp.data.headers.dest_warehouse_name;
                // this.reqDeliveryDate = resp.data.headers.req_delivery_date;
                // this.eta = resp.data.headers.eta;
                // this.$vs.loading.close();
            } else {
                // this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                // momentDate.add(1, 'days');              // Tambahkan 1 hari
                return momentDate.format('DD MMM YYYY');
            };
        },
    },
}
</script>
